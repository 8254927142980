.apps {
    padding: 2% 5%;
    display: flex; 
    flex-wrap: wrap; 
    justify-content: space-around; 
    align-items: center; 
}

.apps .app {
    height: 9em;
    width: 19em;
    margin: 1em;
    border: 1px solid rgb(232, 232, 232, 0.68);
    color: inherit;
    text-decoration: none;
}

.apps .app:hover {
    background-color: rgba(238, 238, 238, 0.493);
}